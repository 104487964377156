<template>
  <section class="mt-4">
    <PageMetadata />
    <modal
      v-if="deleteProductModalShow"
      :closeFn="hideDeleteProductModal"
      closeBtn="cancel"
      :submitFn="voidOrder"
      submitBtn="ok"
    >
      Are you sure, you want to void Order?
    </modal>
    <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <div id="account-profile" class="container">
      <div class="row">
        <h1 class="text-center">{{ getMetaDetails.page_h1 }}</h1>
        <div class="text-center" v-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
      </div>
      <div class="row mb-5">
        <div class="col-md-5">
          <div class="md-form">
            <label for="event_date" class="">From</label>
            <date-picker 
              v-model:dateProp="fromDate"
              @pickerDateChanged="processDateChange"
              :minDate="minDate" 
              :maxDate="maxDate" 
            />
          </div>
        </div>
        <div class="col-md-5">
          <div class="md-form">
            <label for="event_date" class="">To</label>
            <date-picker 
              v-model:dateProp="toDate"
              @pickerDateChanged="processDateChange"
              :minDate="minDate" 
              :maxDate="maxDate" 
            />
            <div v-if="errorDateRange" class="text-danger">
              Please select date range between 170 days.
            </div>
          </div>
        </div>
        <div class="col-md-2 buttons">
          <button type="button" @click="fetchHistory()" class="btn btn-primary w-100" style="margin-top: 21px">View</button>
        </div>
      </div>
      <div class="table-responsive">
        <table v-if="ordersCount > 0" class="table table-striped table-bordered text-center">
          <thead class="bg-grey">
            <tr>
              <th>Invoice<br>Date</th>
              <th>Invoice #</th>
              <th>Prebook #</th>
              <th>Program</th>
              <th>Sub-Total</th>
              <th>Total</th>
              <th>Points</th>
              <th>Redeemed</th>
              <th>Available</th>
              <th>Expiring<br>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(order, index) in orders" :key="index">
              <td>{{ formatDateWithDash(appendTimeForBrowser(order.invoice_date)) }}</td>
              <td>{{ order.invoice_number }}</td>
              <td>{{ order.prebook_number }}</td>
              <td>{{ order.program_code }} <Popper :arrow="true" :hover="true" :content="`${order.program_name}`"><i class="fa-solid fa-circle-info info-icon"></i></Popper></td>
              <td>${{ $filters.numberFormat(order.order_subtotal) }}</td>
              <td>${{ $filters.numberFormat(order.order_total) }}</td>
              <td>{{ $filters.integerNumberFormat(order.points_earned) }}</td>
              <td>{{ $filters.integerNumberFormat(order.points_redeemed) }}</td>
              <td>{{ order.points_available }}</td>
              <td>{{ order.expiring_date != '-' ? formatDateWithDash(appendTimeForBrowser(order.expiring_date)) : order.expiring_date }}</td>
              <td>{{ order.points_status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { misc } from "@/mixins";
import axios from "axios";
import { FlashAlert, Modal, PageMetadata, DatePicker } from "@/components";
import Popper from "vue3-popper";

export default {
  name: "PetalRewardsHistory",
  mixins: [misc],
  components: { FlashAlert, Modal, PageMetadata, Popper, DatePicker },
  data() {
    return {
      loader: "bars",
      isLoading: false,
      deleteProductModalShow: false,
      fullPage: true,
      order_id: this.$route.params.order_id,
      orderInvoiceDetails: {},
      ordersCount: 0,
      errorDateRange: false,
      toDate: '',
      fromDate: '',
      orders: [],
      disabledDates: [],
      minDate: '',
      maxDate: '',
    };
  },
  created() {
    this.minDate = this.appendTimeForBrowser('2017-01-01');
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 8);
    this.maxDate = this.appendTimeForBrowser(this.formatDateWithZeroFromStr(currentDate));
    this.toDate = this.maxDate;
    this.fromDate = this.appendTimeForBrowser(this.formatDateWithZeroFromStr(new Date(currentDate.setDate(currentDate.getDate() - 170))));
    this.emitter.on("deletedProduct", () => {
      this.isLoading = false;
    });
  },
  mounted() {
    localStorage.setItem("currentPage", "petals-rewards-history");
    this.fetchMetaDetails();
    this.emitter.on("showLoader", () => {
      this.showLoader();
    });

    this.fetchHistory();
  },

  methods: {
    showLoader() {
      this.isLoading = true;
    },
    fetchHistory() {
      this.errorDateRange = false;
      let date1 = new Date(this.formatDateWithPaddedZeros(this.toDate));
      let date2 = new Date(this.formatDateWithPaddedZeros(this.fromDate));
      let Difference_In_Time = date1.getTime() - date2.getTime();
      let Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
      if (Difference_In_Days > 170) {
        this.errorDateRange = true;
        this.showFlashAlert("Date range should be less than 170 days", "error");
        return false;
      }

      this.showLoader();
      try {
        axios
          .post(
            process.env.VUE_APP_API_BASE + "auth/getLoyaltyOrders",
            {
              date_from: this.fromDate,
              date_to: this.toDate,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            if (response.data.error == true) {
              this.orders = {};
              this.showFlashAlert(response.data.message, "error");
              this.isLoading = false;
            } else {
              this.orders = response.data.orders;
              this.ordersCount = this.ObjectLength_Modern(this.orders);
              this.isLoading = false;
            }
          });
      } catch (ex) {
        console.log("Catch is here", ex);
        this.isLoading = false;
      }
    },

    ObjectLength_Modern(object) {
      return Object.keys(object).length;
    },
  },
};
</script>